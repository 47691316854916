<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10289')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content" v-if="mmy0109.length > 0">
				<div class="cart__top">
					<div class="item">
						<div class="allcheck" style="padding-left:20px;">
							<div class="allcheck__all">
								{{t('10290')}} ({{selectItemList.data.length}}/{{mmy0109.length}})
							</div>
							<button type="button" class="btn-delete" @click="onClickRemoveSelect">
								{{t('10291')}}
							</button>
						</div>
						<div class="item__menu" style="left:0px;">
							<div class="checkbox">
								<label>
									<input type="checkbox" class="chk blind" @click="onClickSelectAll" :checked="selectAllState">
									<span class="checkbox__bg"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<ul class="cart__list">
					<li v-for="item in mmy0109" :key="item.SEQ">
						<div class="item">
							<div :class="item.CLOSE_YN === 'N' ? 'cart__wrap' : 'cart__wrap--disabled'">
								<div class="cart__meta">
									<h3 class="cart__title">
										<span class="cart__type" style="margin-left:20px;">
											{{item.HOTEL_TYPE}}
										</span>
										<strong>{{item.HOTEL_NM}}</strong>
									</h3>
									<div class="cart__date" v-if="item.PROD_TYPE === '숙박'">
										{{dayjs(item.SDATE).format('YYYY.MM.DD(dd)')}} {{item.STIME}} ~ {{dayjs(item.EDATE).format('YYYY.MM.DD(dd)')}} {{item.ETIME}}
									</div>
									<div class="cart__date" v-else>
										{{dayjs(item.SDATE).format('YYYY.MM.DD(dd)')}} {{item.STIME}} ~ {{item.ETIME}}
									</div>
								</div>
								<div class="cart__item">
									<!-- summary image -->
									<div class="cart__thumbnail noneimage">										                           
										 <div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
									</div>
									<!-- summary item -->
									<div class="cart__itembox">
										<div class="cart__itemdata">
											<h3 class="cart__subtitle">
												{{item.PROD_NM}}
											</h3>
											<p class="cart__option info-slogan">
												기준 {{item.STD_PAX}}인 / 최대{{item.MAX_PAX}}인
											</p>
										</div>
										<ul class="cart__info">
											<li>
												<div class="list__info-type">
													<span class="list__badge">{{item.PROD_TYPE}}</span>
												</div>
												<div class="list__info-price info-price" v-if="item.CLOSE_YN === 'N'">
													<div class="info-price__current">
														<strong>{{item.PROD_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}
													</div>
												</div>
												<div class="list__info-price info-price" v-else>
													<div class="info-price__current">
														<strong><del>{{item.PROD_AMT.toLocaleString("en-US")}}</del></strong>{{t('10088')}}
													</div>
													<strong style="color:#000000;font-weight:bold;">{{t('10293')}}</strong>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div class="item__menu" style="left:00px;">
									<div class="checkbox">
										<label>
											<input 
												type="checkbox"
												:disabled="item.CLOSE_YN === 'Y'"
												class="chk blind"
												@click="onClickSelectItem(item.SEQ)"
												:checked="selectItemList.data.includes(item.SEQ)">
											<span class="checkbox__bg"></span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="box">
					<ul class="info">
						<li class="point point2">
							<h4 class="info__title">
								<strong class="black">총 {{selectItemList.data.length}}건</strong>
							</h4>
							<div class="info__wrap">
								<div class="info__text">
									<strong class="red">{{selectPrice.toLocaleString("en-US")}}</strong>원
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="button-box">
					<button type="button" class="btn" :class="selectItemList.data.length > 0 ? 'btn-confirm' : 'btn-disabled'" :disabled="selectItemList.data.length === 0" @click="handleSubmit">예약하기</button>
				</div>
			</section>
			<section class="content" v-else>
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_cart.png" alt="장바구니에 담긴 상품이 없어요" style="width:64px">
							</div>
							<h3 class="textbox__title">
								장바구니에 담긴 <br> 상품이 없어요
							</h3>
							<p class="textbox__description">
								다양한 숙소를 한번에 예약해 보세요!
							</p>
							<div class="textbox__button">
								<div class="row">
									<router-link to="/rooms">
										<button type="button" class="btn btn-primary purple">
											상품 보러가기
										</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="navigation navigation--nobord" v-if="planList.length > 0">
					<div class="btns">
						<router-link :to="'/event/' + planList[0].PROMO_NO" class="card__link">
							<img :src="planList[0].IMG_URL"  :alt="planList[0].PROMO_DESC">
						</router-link>
					</div>
				</div> -->
			</section>
			<!-- //content -->
		</div>
	</div>
	<HistoryDelete v-if="openMessage" :handleClose="()=> openMessage = false" :handleSubmit="historyDeleteAction"/>
		<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, reactive, ref } from "vue"
import { useRouter } from 'vue-router';
import api from "@/urls/mmy01";
import HistoryDelete from '@/components/layers/Message_historydelete';
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import "dayjs/locale/ko";
import i18n from "@/i18n"; 


dayjs.locale("ko");

export default {
	components: {
		HistoryDelete
	},
	setup: function() {
		const router = useRouter();
		const store = useStore();
	    const { t }= useI18n() //번역필수 모듈

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0109 = computed(() => store.state.mmy01.mmy0109);
		// const planList = computed(() => store.state.mrm01.planList);

		const selectItemList = reactive({data:[]});

		const selectAllState = computed(()=> mmy0109.value.filter(item => item.CLOSE_YN === 'N').length === selectItemList.data.length);
		const selectPrice = computed(()=> mmy0109.value.filter(item => selectItemList.data.includes(item.SEQ)).reduce((acc, cur) => acc + cur.PROD_AMT, 0));
    const openMessage = ref(false);computed
		const historyDeleteAction = ref(() =>{});

		const onClickSelectAll = () =>{
			if(selectAllState.value !== true){
				selectItemList.data = mmy0109.value.filter(item => item.CLOSE_YN === 'N').map(item => item.SEQ);
			}
			else {
				selectItemList.data = [];
			}
		}

		const onClickSelectItem = (seq) =>{
			if(selectItemList.data.includes(seq)) {
				selectItemList.data = selectItemList.data.filter(item => item !== seq);
			}
			else {
				selectItemList.data.push(seq)
			}
		}

		const onClickRemoveSelect = () => {
			if(selectItemList.data.length === 0) return;
			openMessage.value = true;
			historyDeleteAction.value = () => {
				if(selectItemList.data.length === mmy0109.value.length) {
					store.commit("mmy01/SET_MMY0109", { mmy0109: [] });
					
					api.getMmy0109({
						proc_cd: "99",
						mem_id: userData.value.mem_token,
					}).then(()=>{
						store.dispatch("mmy01/fetchMmy0109", {
							proc_cd: "01",
							mem_id: userData.value.mem_token
						});
					});
				}
				else {
					let removePromise = [];
					selectItemList.data.forEach((x) => {
						const data = api.getMmy0109({
							proc_cd: "09",
							mem_id: userData.value.mem_token,
							seq: x
						});
						removePromise.push(data);
					});

					Promise.all(removePromise).then(()=>{
						store.dispatch("mmy01/fetchMmy0109", {
							proc_cd: "01",
							mem_id: userData.value.mem_token
						});
					});
				}
				openMessage.value = false;
				router.go('');
			};
		};

		onMounted(()=>{
			if(isLogin.value){
				store.dispatch("mmy01/fetchMmy0109", {
					proc_cd: "01",
					mem_id: userData.value.mem_token
				});
			}
			// store.dispatch("mrm01/fetchPlanList");
		});

		const goBack = () => {
			router.go(-1);
		}
		const handleSubmit = () => {
			store.commit("order/SET_ORDER_ITEMS", { orderItems: 
				mmy0109.value
					.filter(room => selectItemList.data.includes(room.SEQ))
					.map(room => (
						{
							hotelNo: room.HOTEL_NO,
							prodNo: room.PROD_NO,
							isDayuse: room.PROD_TYPE !== "숙박",
							sdate: room.SDATE,
							edate: room.EDATE,
							sdayuse: room.STIME,
							edayuse: room.ETIME,
							adult_cnt: room.ADULT_CNT ?? room.adult_cnt,
							child_cnt: room.CHILD_CNT ?? room.child_cnt,
						}
					))
			});
			const select_hotel = mmy0109.value.filter(room => selectItemList.data.includes(room.SEQ))

			for (var i = 0; i < select_hotel.length; i++) {
				var hotel = '';
				var hotel2 = '';
				hotel = select_hotel[i].HOTEL_NO+select_hotel[i].PROD_NO+select_hotel[i].SDATE
				for (var i2 = i+1; i2 < select_hotel.length-i; i2++) {
					hotel2 = select_hotel[i2].HOTEL_NO+select_hotel[i2].PROD_NO+select_hotel[i2].SDATE
					
				if(hotel === hotel2){
						alert('중복된 예약이 있습니다.')
						router.push("/order/apply");
						return
				}
				}
			}
			router.push("/order/apply");
		}

		return {
			userData,
			isLogin,
			goBack,
			mmy0109,
			dayjs,
			t,  //번역필수 모듈
		  i18n,
			selectItemList,
			onClickSelectItem,
			onClickSelectAll,
			selectAllState,
			selectPrice,
			onClickRemoveSelect,
			// planList,
			handleSubmit,
			openMessage,
			historyDeleteAction
		}
	}
}
</script>